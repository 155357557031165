import React from 'react'
import shareStyles from './share.module.scss'
import { FaFacebookSquare }  from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaPinterest } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

class FbIco extends React.Component {
  render() {
    return <span><FaFacebookSquare /></span>
  }
}

class TwitterIco extends React.Component {
  render() {
    return <span><FaTwitter /></span>
  }
}

class PinterestIco extends React.Component {
    render() {
        return <span><FaPinterest /></span>
    }
}

class EmailIco extends React.Component {
    render() {
        return <span><MdEmail /></span>
    }
}

const Share = (props) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    let title = 'This is my title';    
    return (
        <div className={shareStyles.shareButtons}>
          <div className={shareStyles.fbButton}><a href={"https://www.facebook.com/sharer/sharer.php?u=" + url} ><FbIco/> Share</a></div>&nbsp;
          <div className={shareStyles.twitterButton}><a href={"https://twitter.com/intent/tweet/?text=Check&nbsp;out&url=" + url + "&via=middleclasswarrior"} target="_blank" rel="noopener noreferrer"><TwitterIco/> Tweet</a></div>&nbsp;
          <div className={shareStyles.pinterestButton}><a href={"http://pinterest.com/pin/create/button/?url=" + url} class="pin-it-button" count-layout="horizontal"><PinterestIco/> Pin</a></div>&nbsp;
          <div className={shareStyles.emailButton}><a href={"mailto:?subject=" + title + "&amp;body="+ url}><EmailIco/> Email</a></div>
        </div>
    )
}

export default Share
